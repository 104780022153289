.popup {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  button.popup-close {
    margin: 24px;
    border: none;
    background: none;
    color: $yellow;
    font-weight: bold;
    font-size: 30px;
  }
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
#popup-1 {
  div {
    padding: 24px;
    @include sm() {
      padding: 72px;
    }
    width: 80%;
    height: 236px;
    background: white;
    border: solid 1px $light-gray-darker;
    margin: 200px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -40%;
    p {
      line-height: 1.6em;
    }
  }
}
#popup-2 {
  div {
    padding: 72px;
    width: 380px;
    height: 280px;
    background: $dark-gray;
    margin: -140px 0 0 -190px;
    p {
      color: white;
      font-weight: bold;
      a, span {
        display: inline-block;
        margin: 12px;
        color: $yellow;
      }
      a {
        font-size: 18px;
      }
      span {
        font-size: 36px;
        font-weight: 100;
      }
    }
  }
}
body.popup-1-shown #popup-1 {
  display: block;
}

body.popup-2-shown #popup-2 {
  display: block;
}
