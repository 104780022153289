$height: 360px;
$height-lg: 420px;

.cover-wrap {
  position: relative;
  height: $height;
  @include lg {
    height: $height-lg;
  }

  .alice-carousel ul li {
    height: 420px;
  }
}

.cover {
  position: absolute;
  width: 100%;
  top: 0;
  background: $yellow;

  &.-has-image {
    background-color: #f3f0e7;
    @include sm {
      background-size: 750px;
      background-position: center;
      transform: scaleX(-1);
    }
    @include md {
      background-size: 970px;
    }
    @include lg {
      background-size: 1170px;
    }

    .row {
      @include xs {
        margin: 0;
      }
    }
  }

  &.-no-image {
    height: $height;
    @include lg {
      height: $height-lg;
    }
  }

  // .carousel-fade .carousel-item.active {
  //   z-index: 0;
  // }

  .cover-image {
    height: $height;
    background-size: cover;
    background-position: center;
    @include sm {
      transform: scaleX(-1);
    }
    @include lg {
      height: $height-lg;
    }
  }
}

// .alice-carousel__wrapper {
//   height: 100%;
// }
