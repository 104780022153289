$freebirdPink: #dd0064;
$freebirdHeight: 64px;

p.footer-links {
  padding-top: 40px;
  // margin-bottom: -14px;

  a {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.visual {
      height: 34px;
      width: 34px;
    }

    &:not(.visual) {
      margin: 0;
    }
  }
  
  @include xs {
    padding-top: 0px;
  }

  &.in-drawer {
    padding-top: 10px;
    a {
      text-align: center;
      height: 26px !important;
      width: 26px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    .facebook-icon-yuge {
      margin-right: 0px !important;
    }

    @include xs {
      .mail-icon-yuge {
        background-image: url('../../img/mail-web.svg');
      }

      .instagram-icon-yuge {
        background-image: url('../../img/IG-web.svg');
      }

      .facebook-icon-yuge {
        background-image: url('../../img/fb-web.svg');
      }
    }
  }

  &.overlap {
    .mail-icon-yuge {
      background-image: url('../../img/mail-web.svg');
    }

    .instagram-icon-yuge {
      background-image: url('../../img/IG-web.svg');
    }

    .facebook-icon-yuge {
      background-image: url('../../img/fb-web.svg');
      margin-right: 5px !important;
    }
  }

  .mail-icon-yuge {
    background-image: url('../../img/mail-web-grey.svg');
  }

  .instagram-icon-yuge {
    background-image: url('../../img/IG-web-grey.svg');
  }

  .facebook-icon-yuge {
    background-image: url('../../img/fb-web-grey.svg');
    margin-right: 0px !important;
  }
}

footer {
  font-weight: 400 !important;
  font-family: 'Helvetica', 'Arial', sans;
  background: #f2f2f2;
  text-align: center;
  margin-top: 20px;

  #languagePicker {
    padding-top: 32px;
    text-transform: uppercase;

    img {
      box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.4);
    }

    button {
      text-transform: uppercase;
      border: none;
      background: no-repeat;
    }
  }

  a,
  button {
    text-align: center;
    color: $yellow;
    padding: 0 10px 0 10px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  .badges {
    padding-top: 34px;
  }

  p.footer-legal {
    padding-top: 25px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    line-height: 25px;
  }
  p.footer-freebird {
    font-weight: 400 !important;
    color: $freebirdPink;
    a {
      color: $freebirdPink;
      text-decoration: underline;
    }

    border-bottom: 3px solid $freebirdPink;
  }
  div.footer-bottom {
    height: $freebirdHeight;
    background: $white;
    p {
      margin: 0;
      line-height: $freebirdHeight;
      font-size: 14px;
    }
  }
}
