.product {
  margin-bottom: 108px;
  @include sm() {
    margin-bottom: 144px;
  }
  .img-wrap {
    margin: 0 auto;
    width: auto;
    height: 0;
    padding-bottom: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    @media (min-width: 375px) {
      margin-top: 36px;
      width: 375px;
      height: 375px;
      padding-bottom: 0;
      -webkit-box-shadow: 0px 1px 12px 0px rgba(189,189,189,1);
      -moz-box-shadow: 0px 1px 12px 0px rgba(189,189,189,1);
      box-shadow: 0px 1px 12px 0px rgba(189,189,189,1);
    }
  }

  .price {
    display: block;
    padding-top: 12px;
    font-size: 19px;
    min-height: 39px;
  }
}
