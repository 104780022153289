.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.t-left{
  padding-left: 48px !important;
  text-align: left !important;
  color: $yellow;
}

.menu-searchbar {
  -webkit-transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;

  margin: 0;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #c4c4c4;
  display: block;
  height: 44px;
  width: 100%;

  @media (min-width: 768px) {
    background: #f2f2f2;
    border-bottom: none;
  }

  overflow: hidden;
  text-align: center;

  ul {
    list-style: none;
    padding: 0 22px 0 22px;
    margin: 0 auto 0 auto;
    width: 100%;
    text-align: left;
    white-space: nowrap;

    // Match overall indent from Bootstrap.
    // @TODO: Use resolution mixins
    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 970px;
    }

    @media (min-width: 1200px) {
      width: 1170px;
    }

    li {
      display: inline-block;
      // -webkit-transition: width 0.2s ease;
      // -moz-transition: width 0.2s ease;
      // -o-transition: width 0.2s ease;
      // transition: width 0.2s ease;
      white-space: nowrap;
      overflow: visible;

      width: 100%;

      span {
        display: block;
        position: relative;

        -webkit-transition: width 0.2s ease;
        -moz-transition: width 0.2s ease;
        -o-transition: width 0.2s ease;
        transition: width 0.2s ease;

        &:before {
          position: absolute;
          left: calc(50% - 50px);
          top: 0;
          bottom: 0;
          content: '';
          width: 20px;
          background: url('../../img/search.svg') left center no-repeat;
          pointer-events: none;
        }

        &.leftAligned:before {
          left: 0;
        }

        &.partial-width {
          width: 150px;
        }

        &.full-width {
          width: 100%;
        }

        input {
          font-family: $font-helvetica-neue-regular;
          display: inline-block;
          background: rgba(0, 0, 0, 0);
          padding-left: 40px;
          font-size: 16px;
          display: block;
          height: 44px;
          border: none;
          outline: none;
          
          width: 100%;
          white-space: nowrap;
          overflow: visible;
          text-align: center;
        }

        input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: $yellow;
        }
      }
    }
  }
}

.search-row {
  margin: 0;
  padding: 0;
}

#query-message {
  text-align: center;

  button {
    text-decoration: none;
    display: block;
    font-weight: 600;
    background-color: #ffb000;
    width: 150px;
    float: right;
    margin-top: -36px;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 4px;
    cursor: pointer;
    z-index: 10;
  }

  span:before {
    background: none;
  }
}
