@import 'fonts';
@import 'colors';

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 70% !important;
    margin: 5px auto 30px !important;
  }
}

.modal-header,
.modal-footer {
  border: 0 !important;
}

.modal-footer {
  padding-top: 0 !important;

  button {
    margin-top: 0;
    background-color: rgb(250,204,21) !important;
    border-color: transparent !important;
    color: #975a16;
    font-weight: 500;
  }

  .link-button,
  .link-button:hover,
  .link-button:focus {
    background-color: transparent !important;
    color: #ffb000;
    padding-left: 0;
    padding-right: 0;
  }

  button:hover,
  button:focus {
    background-color: #ffb000 !important; //$light-green;
    color: #975a16;
  }
}

button#languageSelector {
  outline: none;
}

.modal-backdrop,
.modal-content {
  background-color: none;
  opacity: 1 !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.modal {
  overflow-y: hidden !important;
}

.modal-dialog {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog,
.modal-content {
  height: auto;
}

.modal-content {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 1rem !important;
  padding: 10px;
}

.modal-data {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  h2 {
    text-align: center;
    color: white;
  }

  input {
    margin: 15px 0;
    height: 3.2em;
  }

  input:last-of-type {
    margin-bottom: 0;
  }
}

.centered {
  display: table;
  margin: 0 auto 50px;
}

.free-trial-btn {
  padding: 1.0625em 1.875em;
  border-radius: 4px;
  font-family: $font-helvetica-neue-medium;
  font-size: 1em;
  line-height: 1.133;
  -webkit-font-smoothing: antialiased;
  transition: 150ms ease;
  transition-property: background-color, border-color, box-shadow, color;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060; //$light-green;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-transform: uppercase;

  span {
    color: $white !important;
  }
}

.free-trial-btn:hover,
.free-trial-btn:focus {
  background-color: #004c3f; //$light-green;
}

.free-trial-account {
  padding: 1.0625em 1.875em;
  border-radius: 4px;
  font-family: $font-helvetica-neue-medium;
  font-size: 1em;
  line-height: 1.133;
  -webkit-font-smoothing: antialiased;
  transition: 150ms ease;
  transition-property: background-color, border-color, box-shadow, color;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #BD10E0 !important;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  font-weight: bold;
  
  span {
    color: $white !important;
  }

}

.free-trial-account:hover,
.free-trial-account:focus {
  background-color: #8c00af; //$light-green;
}

.free-trial-spinner {
  color: #ffb000 !important;
}

#drawer .free-trial-btn {
  height: auto;
  font-size: 1.5em;
}
