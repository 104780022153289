.menu-categories {
  a,
  button {
    background-image: none !important;
    background-color: transparent !important; // override color set in view
    background: none;
    border-radius: 0;
    height: auto;
    width: auto;
    color: #9b9b9b;
    text-indent: 0;
    padding-bottom: 2px;
    margin-right: 8px;

    &:before {
      display: inline-block;
    }
  }
}
