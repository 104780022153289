nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $yellow;
  padding-bottom: 18px;
  transition: bottom 0.2s ease-in-out;

  body.nav-hidden &,
  body.nav-hidden-temp & {
    bottom: -200px;
  }

  a,
  a:hover,
  p {
    color: white;
    font-size: 14px;
    @include md() {
      font-size: 18px;
    }
  }
  a {
    font-weight: 400;
    padding: 0 6px;
  }
  p {
    font-weight: 100;
    font-style: italic;
  }

  .row {
    padding-top: 16px;
  }

  button {
    background: none;
    border: none;
    outline: none;
    color: white;
    padding: 0 4px;
    span {
      font-size: 14px;
      @include md() {
        font-size: 18px;
      }
    }
  }

  .badge-banner-slogan {
    font-style: normal;
    margin-top: 20px;
  }
}

a.badge-button {
  width: 135px;
  height: 40px;
}

a.badge-iphone,
a.badge-android {
  display: block;
  margin: 0 auto 30px;
  @include md() {
    display: inline-block;
    margin: 0px 12px 0;
  }
  width: 135px;
  height: 40px;
  vertical-align: top;
  padding: 0;
}

a.badge-iphone {
  background-image: url('../img/Download_on_the_App_Store_Badge_US-UK_135x40.svg');
}

a.badge-android > img {
  margin-bottom: 8px;
}

span.badge-arcore {
  font-size: 11px;
  font-weight: bold;
  color: #333;
  img {
    width: 20px;
    height: 20px;
    display: inline;
    margin-left: 8px;
  }
}
