// fonts
$font-helvetica-neue-medium: "HelveticaNeue-Medium", "Helvetica Neue Medium", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-helvetica-neue-regular: "HelveticaNeue", "Helvetica Neue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-helvetica-neue: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-helvetica-neue-ultra-light: "HelveticaNeue-Ultra-Light", "Helvetica Neue Ultra Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

// font sizes
$font-size-extra-large: 55px;
$font-size-extra-large-md: 70px;
$font-size-extra-large-lg: 80px;

$font-size-large: 40px;
$font-size-large-md: 52px;
$font-size-large-lg: 60px;

$font-size-body: 18px;
$font-size-body-md: 24px;
$font-size-body-lg: 24px;

$font-size-medium: 20px;
$font-size-medium-md: 24px;
$font-size-medium-lg: 24px;

$font-size-button-large: 16px;
$font-size-button-large-md: 18px;
$font-size-button-large-lg: 20px;

$font-size-medium-large: 24px;
$font-size-medium-large-md: 20px;
$font-size-medium-large-lg: 30px;
