$hamburger-layer-color: #fff;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-width: 30px;
$hamburger-types: (collapse);

* {
  font-family: system-ui,-apple-system,"Segoe UI","Roboto","Helvetica","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji" !important;
}

.picker {
  color: black !important;
}

header {
  height: 100% !important;
  background: white;
}

.header-wrap {
  background: white;
  height: 60px;

  ul div{
    padding: 3px;
  }

  .hamburger {
    outline: none;
  }

  @media (min-width: 768px) {
    height: 100px;
    background: white;
  }

  &:not(.overlap) {
    @media (min-width: 768px) {
      .hamburger-inner:before {
        background-color: $yellow;
      }

      .hamburger-inner {
        background-color: $yellow;
      }

      .hamburger-inner:after {
        background-color: $yellow;
      }

      .back-shop {
        color: $yellow;
      }

      * {
        color: #9b9b9b;
      }
    }
  }

  &.overlap {
    position: absolute;
    z-index: 10000;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));

    @include maxmd {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  header {
    width: 100% !important;

    // Match overall indent from Bootstrap.
    @media (min-width: 768px) {
      // width: calc(750px) !important;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (min-width: 992px) {
      // width: calc(970px) !important;
    }

    @media (min-width: 1200px) {
      // width: calc(1170px) !important;
    }

    padding-left: 40px;
    padding-right: 40px;
    @include xs() {
      padding-left: 20px;
      padding-right: 20px;
    }

    height: 80px;

    @include xs {
      padding-top: 0;
      height: 50px;
    }

    border-bottom: none;

    h1 {
      padding-top: 0;
      margin-top: 14px;
      @include sm() {
        margin-top: 20px;
      }
      @include lg() {
        margin-top: 20px;
      }
    }

    .header-menu {
      padding-top: 0;
      @include sm() {
      }
      @include lg() {
      }
    }

    h1 {
      font-size: 0;
      height: auto;
    }

    .link-home {
      display: inline-block;
      width: 120px;
      height: 25px;
      @include xs() {
        width: 80px;
        margin-top: 4.5px;
      }
    }

    .back-shop {
      color: white;
      font-family: $font-helvetica-neue-medium;
      font-size: 16px;
      display: inline-block;
      line-height: 60px;
      height: 60px;
      margin-bottom: -60px;

      @include xs {
        height: 50px;
        line-height: 50px;
        margin-bottom: -50px;
      }
    }

    .header-menu {
      height: 24px;

      div {
        display: inline-block;
        margin: 0 4px;
        cursor: pointer;

        @include sm() {
          margin: 0 8px;

        }
        @include xs() {
          margin: 0 2px;
          padding: 5px;
        }
        @include maxmd {
          display: none;
        }
        &.-is-active span {
          color: $yellow;
        }
      }

      a {
        font-family: $font-helvetica-neue-medium;
        display: inline-block;
        margin: 0 4px;
        cursor: pointer;
        font-size: 16px;
        text-decoration: none !important;
        @include sm() {
          margin: 0 8px;
        }
        @include xs() {
          margin: 0 2px;
        }
        @include maxmd {
          display: none;
        }
        &.-is-active span {
          color: $yellow;
        }
      }

      .hamburger {
        @include md {
          display: none;
        }
      }
    }
  }
}
