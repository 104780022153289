.block {
  background: $light-gray;
  @include lg() {
    padding-top: 50px;
  }

  .block-image {
    height: 600px;
    padding: 0;
    background-size: cover;
    background-position: center;
    position: relative;

    .block-image-hover {
      width: 100%;
      height: 600px;
      background: rgba(0, 0, 0, 0.5);
    }

    .block-image-hover p {
      font-size: $font-size-extra-large;
      @include sm() {
        font-size: $font-size-extra-large-md;
      }
      @include lg() {
        font-size: $font-size-extra-large-lg;
      }
      font-weight: bold;
      line-height: 1.4em;
      top: 188px;
      @include sm() {
        top: 118px;
      }
      @include md() {
        top: 124px;
      }
    }

    $topEnd: 408px;
    $topDiff: 50px;
    $transitionSpeedOpacity: 0.4s;
    $transitionSpeedTop: 0.6s;

    .block-image-hover {
      position: absolute;
      width: 100%;
      transition: opacity ease-out $transitionSpeedOpacity;

      p {
        transition: opacity ease-out $transitionSpeedOpacity;
      }
    }

    .block-image-hover + p {
      top: $topEnd + $topDiff;
      opacity: 0;
      transition:
        top ease-out $transitionSpeedTop,
        opacity ease-out $transitionSpeedOpacity;
      font-weight: bold;
      line-height: 1.2em;
      font-size: $font-size-medium;
      padding-left: 40px;
      padding-right: 40px;
      @include sm() {
        font-size: $font-size-medium-md;
      }
    }

    &:hover {
      .block-image-hover {
        &:not(.amsterdam) {
          opacity: 0;
          & + p {
            opacity: 1;
            top: $topEnd;
          }
        }

        &.amsterdam {
          opacity: 0.5;
          p {
            opacity: 0;
          }
          & + p {
            opacity: 1;
            top: $topEnd;
          }
        }
      }
    }

    .block-image-hover.block-image-hover-hide {
      &:not(.amsterdam) {
        opacity: 0;
        & + p {
          opacity: 1;
          top: $topEnd;
        }
      }

      &.amsterdam {
        opacity: 0.5;
        p {
          opacity: 0;
        }
        & + p {
          opacity: 1;
          top: $topEnd;
        }
      }
    }
  }

  .block-image-1 {
    &:not(.amsterdam) {
      background-image: url('../../img/photo/xs/1.jpg');
      @include retina() {
        background-image: url('../../img/photo/xs/1_@2x.jpg');
      }
      @include sm() {
        background-image: url('../../img/photo/sm/1.jpg');
        @include retina() {
          background-image: url('../../img/photo/sm/1_@2x.jpg');
        }
      }
      @include md() {
        background-image: url('../../img/photo/md/1.jpg');
        @include retina() {
          background-image: url('../../img/photo/md/1_@2x.jpg');
        }
      }
    }

    &.amsterdam {
      background-image: url('../../img/photo/xs/1_amsterdam.jpg');
      @include retina() {
        background-image: url('../../img/photo/xs/1_amsterdam_@2x.jpg');
      }
      @include sm() {
        background-image: url('../../img/photo/sm/1_amsterdam.jpg');
        @include retina() {
          background-image: url('../../img/photo/sm/1_amsterdam_@2x.jpg');
        }
      }
      @include md() {
        background-image: url('../../img/photo/md/1_amsterdam.jpg');
        @include retina() {
          background-image: url('../../img/photo/md/1_amsterdam_@2x.jpg');
        }
      }
    }
  }
  .block-image-2 {
    background-image: url('../../img/photo/xs/2.jpg');
    @include retina() {
      background-image: url('../../img/photo/xs/2_@2x.jpg');
    }
    @include sm() {
      background-image: url('../../img/photo/sm/2.jpg');
      @include retina() {
        background-image: url('../../img/photo/sm/2_@2x.jpg');
      }
    }
    @include md() {
      background-image: url('../../img/photo/md/2.jpg');
      @include retina() {
        background-image: url('../../img/photo/md/2_@2x.jpg');
      }
    }
  }
  .block-image-3 {
    background-image: url('../../img/photo/xs/3.jpg');
    @include retina() {
      background-image: url('../../img/photo/xs/3_@2x.jpg');
    }
    @include sm() {
      background-image: url('../../img/photo/sm/3.jpg');
      @include retina() {
        background-image: url('../../img/photo/sm/3_@2x.jpg');
      }
    }
    @include md() {
      background-image: url('../../img/photo/md/3.jpg');
      @include retina() {
        background-image: url('../../img/photo/md/3_@2x.jpg');
      }
    }
  }
  .block-image-text {
    color: $white;
    position: relative;
    font-size: $font-size-button-large;
    font-weight: normal;
    top: 360px;
    @include sm() {
      top: 460px;
      font-size: $font-size-button-large-md;
    }
    @include lg() {
      font-size: $font-size-button-large-lg;
      top: 400px;
    }
    line-height: 1.9em;
  }
  .block-text {
    min-height: 460px;
    background: $white;
    font-weight: 100;
    h3, p {
      margin: 0;
      padding: 36px 0;
      text-align: center;
      @include sm() {
        text-align: left;
        $paddingHor: 75px - 15px;
        $paddingVert: 50px;
        padding: $paddingVert $paddingHor;
      }
      @include lg() {
        $paddingHor: 100px - 15px;
        $paddingVert: 75px;
        padding: $paddingVert $paddingHor;
      }
    }
    h3 {
      font-size: $font-size-large;
      @include sm() {
        font-size: $font-size-large-md;
      }
      @include lg() {
        font-size: $font-size-large-lg;
      }
      line-height: 1.3em;
      margin-top: 24px;
    }
    p {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;
      @include sm() {
        padding-top: 75px + 14px;
        padding-bottom: 75px + 14px;
      }
      font-size: $font-size-body;
      @include sm() {
        font-size: $font-size-body-md;
      }
      @include lg() {
        font-size: $font-size-body-lg;
      }
      line-height: 1.6em;
    }
  }
}

.block-text-menu {
  min-height: 200px;
  ul {
    padding-left: 0;
    margin-top: 86px;
    margin-bottom: 79px;
    li {
      list-style: none;
      @include sm() {
        display: inline;
      }
      padding: 0 12px;
      a {
        font-size: $font-size-medium;
        @include sm() {
          font-size: $font-size-medium-md;
        }
        @include lg() {
          font-size: $font-size-medium-lg;
        }
        color: $text-color;
        font-weight: 100;
      }
    }
  }
}

.block-text-features {
  margin-bottom: 36px;
  h1 {
    margin-top: 52px;
    margin-bottom: 24px;
    color: $text-color;
    font-size: $font-size-medium-large;
    @include sm() {
      font-size: $font-size-medium-large-md;
    }
    @include lg() {
      font-size: $font-size-medium-large-lg;
    }
    font-weight: 100;
  }
  .features-icon {
    $dim: 150px;
    width: $dim;
    height: $dim;
    border-radius: $dim;
    @include sm() {
      $dim: 150px;
      width: $dim;
      height: $dim;
      border-radius: $dim;
    }
    background: $light-gray;
    margin: 48px auto;
    border: solid 1px $light-gray-darker;
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: center;
    &.features-icon-phones {
      background-image: url('../../img/phones.svg');
    }
    &.features-icon-devices {
      background-image: url('../../img/devices.svg');
    }
    &.features-icon-gears {
      background-image: url('../../img/gears.svg');
    }
  }
  .features-icon + h4 {
    width: 140px;
    margin: 0 auto 36px;
    line-height: 1.4em;
  }
}

.block-pricing {
  padding-bottom: 40px;
}

.pricing-wrap {

  h5, p, span {
    color: $black;
  }

  &.pricing-wrap-selected {
    h5, span, p {
      color: $yellow;
    }
  }

  h5, span, p {
    $dim: 36px;
    padding-top: $dim;
    padding-bottom: $dim;
  }
  h5 {
    margin: 0;
    font-family: $font-helvetica-neue;
    font-size: $font-size-large;
    @include sm() {
      font-size: $font-size-large-md;
    }
    @include lg() {
      font-size: $font-size-large-lg;
    }
  }
  span {
    font-size: $font-size-medium;
    @include sm() {
      font-size: $font-size-medium-md;
    }
    @include lg() {
      font-size: $font-size-medium-lg;
    }
    font-weight: bold;
  }
  p {
    width: 72%;
    margin: 0 auto;
  }
}

.pricing-more {
  display: inline-block;
  margin: 36px 0;
  font-weight: bold;
}

.block-text-shops {
  background: $white;
  h1 {
    color: $text-color;
    font-size: $font-size-medium-large;
    @include sm() {
      font-size: $font-size-medium-large-md;
    }
    @include lg() {
      font-size: $font-size-medium-large-lg;
    }
    font-weight: 100;
    margin: 36px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.order-button-wrap {
  margin-top: 72px;
  margin-bottom: 48px;
  button {
    display: block;
    margin: 0 auto;
    background: $yellow;
    border: none;
    outline: none;
    color: white;
    padding: 16px 72px;
    border-radius: 8px;
    font-size: $font-size-button-large;
    @include sm() {
      font-size: $font-size-button-large-md;
    }
    @include lg() {
      font-size: $font-size-button-large-lg;
    }
    text-transform: uppercase;
    font-weight: 600;
  }
}

.order-wrap {
  $dim: 1px;
  background: $white;
  .row {
    margin-right: $dim;
    margin-left: $dim;
  }

  .row > div:nth-child(2n+0) {
    @include xs() {
      display: none;
    }
  }

  a.merchant-anchor {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 66%;
    height: 0;

    > div {
      position: absolute;
      top: 0;
      width: 100%;
      padding-bottom: 66%;
    }

    > div.merchant-anchor-hover {
      z-index: 99;
      background-color: rgba($black, 0.6);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      h6 {
        color: $white;
        text-align: center;
        font-size: $font-size-medium;
        position: absolute;
        top: 40%;
        width: 100%;
        @include sm() {
          font-size: $font-size-medium-md;
        }
        @include lg() {
          font-size: $font-size-medium-lg;
        }
        span {
          font-size: 14px;
        }
      }

    }

    &:hover {
      > div.merchant-anchor-hover {
        opacity: 1;
      }
    }

    > div.merchant-anchor-image {
      z-index: 9;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: solid 1px $light-gray;
      transition: opacity 1s ease-in-out;

      &.js-hidden {
        opacity: 0;
      }
    }
  }
}

.block-text-menu {
  background: $white;
  li {
    margin-bottom: 12px;
  }
}

.block-contact {
  margin-top: 125px;
  margin-bottom: 50px;

  a.block-contact-anchor {
    $dim: 100px;
    display: inline-block;
    width: $dim;
    height: $dim;
    margin: 0 20px;
    @include sm() {
      margin: 0 80px;
    }
    background-size: contain;
    background-repeat: no-repeat;
    svg {
      display: inline-block;
      width: $dim;
      height: $dim;
      fill: $text-color;
      transition: fill ease-out .2s
    }
    &:hover {
      svg {
        fill: $yellow;
      }
    }
  }
  p {
    margin-top: 100px;
    font-size: $font-size-medium;
    @include sm() {
      font-size: $font-size-medium-md;
    }
    @include lg() {
      font-size: $font-size-medium-lg;
    }
  }
}
