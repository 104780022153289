#drawer:before {
  content: "";
  height: 100%;
}
#drawer:before,
ul {
  display: inline-block;
  vertical-align: middle;
}

.yellow-text {
  color: $yellow !important;
}

.hidden {
  visibility: hidden;
}

#drawer {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  @media (min-width: 768px) {
    border-top: none;
    background-color: $white;

    &:not(.overlap) {
      border-bottom: 1px solid #f2f2f2;
      * {
        color: #9b9b9b !important;
      }
    }
  }

  display: block;
  // background-color: $yellow;
  border-top: 1.5px solid #f2f2f2;
  border-bottom: 1.5px solid #f2f2f2;
  color: red !important;
  // Animate to 152px
  height: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    display: none;
  }

  margin-top: 0px;
  text-align: center;

  ul {
    list-style: none;
    padding: 10px;
    text-align: center;

    li {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    a {
      height: 22px;
      color: rgb(107,114,128);
      font-family: "Helvetica", sans-serif;
      display: inline-block;
      margin: 10px;
    }
  }

  &.openDrawer {

    @include maxmd {
      height: 80vh !important;

      &.overlap {
        height: 255px !important;

        @include xs {
          height: 285px !important;
        }
      }

      &.product-header {
        height: 279px !important;
      }
    }
  }

  &.overlap {
    border-top: none;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 80px;
    @include xs {
      margin-top: 50px;
    }
    z-index: 10000;
    position: absolute;
    ul {
      margin-top: -20px;
      a {
        font-size: 1.6em;
        padding: 10px;
      }
    }
  }
}

body {
  background: $white;
  margin-bottom: 0;
  font-family: $font-helvetica-neue;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $text-color;
  font-family: $font-helvetica-neue;
  font-weight: 100;
}

.gm-style-iw {
  h3 {
    text-align: center;
    margin-bottom: 20px;
    font-family: $font-helvetica-neue-medium;
  }

  p {
    text-align: center;
    font-family: $font-helvetica-neue;
  }

  a {
    display: block;
    width: 100%;
    text-align: center !important;
    font-family: $font-helvetica-neue;
    font-weight: 600;
    color: $yellow;

    &:hover {
      color: darken($yellow, 4);
    }
  }

  .openingStatus {
    font-family: $font-helvetica-neue;
    font-weight: 600;
  }
}

a {
  color: $text-color;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.-is-uppercased {
  text-transform: uppercase;
}

.button {
  background-color: $yellow;
  color: white;
  transition: background 0.2s ease-in-out;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background: lighten($yellow, 8%);
    text-decoration: none;
  }
  border-radius: 2px;
  &.button-large {
    border-radius: 8px;
  }
}

// Fixes Chrome FOUC issue.
html.js [lang]:not(.lang-correct) {
  display: none !important;
}
