$white: #fff;
$black: #000;

$yellow: #ffb000;
$yellow-block: #f7d441;
$light-yellow: #fae16b;

$dark-gray: #4a4a4a;
$light-gray: #f2f2f2;
$light-gray-darker: darken($light-gray, 10%);
$light-gray-lighter: #fbfbfb;
$lighter-gray: #eee;

$text-gray: #9b9b9b;
$text-color: $dark-gray;

$gray: #d5cdcb;
$light-green: #7edda5;
