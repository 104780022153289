#main-product {
  text-align: center;

  #main-image {
    display: inline-block;
    width: 100%;
    min-height: 400px;
    background-color: clear;
    border: 1px solid #c4c4c4;

    @include xs {
      width: 280px;
      margin-left: calc(50% - 140px);
      margin-right: calc(50% - 140px);
      height: 280px;
    }

    @include xxs {
      width: 200px;
      margin-left: calc(50% - 100px);
      margin-right: calc(50% - 100px);
      height: 200px;
    }

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  h3 {
    font-weight: bold;
    color: #ffb000;
    padding: 20px;
    font-size: 24px;
  }

  p {
    padding: 0px 10% 20px 10%;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  .product-price-wrap {
    margin: 0 10% 0 10%;
    position: relative;
    min-height: 70px;
  }

  .ar-button {
    display: block;
    margin: 0 auto;
    width: 92px;
    height: 60px;
    background-image: url("../img/ARKit_Badge_IconAndText.svg");
    background-repeat: no-repeat;
    border-radius: 4px;
  }

  #product-pop-up-webshop {
    display: inline-block;
    width: 30px;
    height: 28px;
    background-image: url("../img/cart.svg");
    margin: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }

  #product-pop-up-description {
    font-size: 14px;
  }

  #other-products-header {
    padding-top: 40px;
    color: #ffb000;
    font-weight: bold;
  }

  #other-products {
    padding: 0px 10% 20px 10%;

    .fake-img {
      background-color: clear;
      width: 28%;
      height: auto;
      margin: 2%;
      display: inline-block;

      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
