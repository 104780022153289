.i18n {
  display: inline-block;
  padding-left: 0;
  margin-right: 40px;
  padding-top: 4px;
  li {
    display: inline-block;
    padding-right: 6px;

    a {
      text-indent: -9999px;
      display: inline-block;
    }
  }
}
