.merchant-wrap {
  margin: 24px 0 24px 0;
  position: relative;

  a {
    display: block;
  }

  div.merchant-img {
    padding-bottom: 66%;
    background-size: cover;
    background-position: center;
  }

  div.merchant-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;

    h1 {
      position: absolute;
      bottom: 24px;
      color: white !important;
      text-shadow: 0 0 5px black;

      padding: 0 24px;
      text-align: center;
      width: 100%;
      color: white;
      text-shadow: 0 0 5px black;
      font-size: 24px;
      font-family: $font-helvetica-neue-regular;
      font-weight: 500;

      @include xs {
        text-align: left;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

.pagination-button-wrap {
  font-family: $font-helvetica-neue-regular;
  font-size: 18px;
  font-weight: 500;
  display: table;
  margin: 16px auto 32px auto;
}

.pagination-btn {
  color: $yellow !important;
  background: none;
  border: none;
  height: 64px;

  &:focus,
  &:active {
    outline: none;
  }
}

// button.pagination-btn:focus {
//   outline: none;
// }
