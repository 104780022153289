:root {
  --logo-anim-speed-factor: 1.8;
}

.yStrokeBowl {
  clip-path: url(#yClip);
  stroke-dasharray: 195;
  stroke-dashoffset: 195;
  animation-delay: calc(0s / var(--logo-anim-speed-factor));
  animation-duration: calc(2s / var(--logo-anim-speed-factor));
}

.yStrokeStraight {
  clip-path: url(#yClip);
  stroke-dasharray: 195;
  stroke-dashoffset: 195;
  animation-delay: calc(0.035s / var(--logo-anim-speed-factor));
  animation-duration: calc(2s / var(--logo-anim-speed-factor));
}

.uStroke {
  clip-path: url(#uClip);
  stroke-dasharray: 308;
  stroke-dashoffset: 308;
  animation-delay: calc(0.3s / var(--logo-anim-speed-factor));
  animation-duration: calc(1.6s / var(--logo-anim-speed-factor));
}

.gStroke {
  clip-path: url(#gClip);
  stroke-dasharray: 404;
  stroke-dashoffset: 404;
  animation-delay: calc(0.6s / var(--logo-anim-speed-factor));
  animation-duration: calc(2s / var(--logo-anim-speed-factor));
}

.eStrokeBowl {
  clip-path: url(#eClip);
  stroke-dasharray: 252;
  stroke-dashoffset: 252;
  animation-delay: calc(0.9s / var(--logo-anim-speed-factor));
  animation-duration: calc(1.85s / var(--logo-anim-speed-factor));
}

.eStrokeStraight {
  clip-path: url(#eClip);
  stroke-dasharray: 252;
  stroke-dashoffset: 252;
  animation-delay: calc(0.935s / var(--logo-anim-speed-factor));
  animation-duration: calc(1.85s / var(--logo-anim-speed-factor));
}

.animStroke {
  animation-name: dash;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.lightStroke {
  stroke: #eee;
  animation-timing-function: cubic-bezier(0.35, 0, 0.35, 0.8);
}

.darkStroke {
  stroke: #ccc;
  animation-timing-function: cubic-bezier(0.5, 0, 0.5, 0.8);
}

.yellowStroke {
  stroke: #ffb000;
  animation-timing-function: cubic-bezier(0.65, 0, 0.65, 0.8);
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

svg#logo {
  width: 80px;
}
@media (min-width: 780px) {
  svg#logo {
    width: 120px;
  }
}
svg#logo.logo-white .yellowStroke {
  stroke: #fff;
}
@media (max-width: 779px) {
  svg#logo .yellowStroke {
    stroke: #fff;
  }
}
