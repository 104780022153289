.page-index {
  h1,
  h2 {
    text-align: center;
  }
  h1 {
    font-size: 40px;
    @include md() {
      font-size: 60px;
    }
    color: $yellow;
    margin: 36px 0 0;
    word-break: break-word;
  }
  .merchant-grid-ax {
    margin-right: 3px !important;
    margin-left: 3px !important;
    h2 {
      margin: 0 !important;
      text-align: left !important;
    }
    h7 {
      color: red;
    }
  }
  h2 {
    @include md() {
      font-size: 24px;
    }
    color: $text-gray;
    margin: 26px 0 72px;
  }
}

.page-pricing {
  h1,
  h2 {
    text-align: center;
  }
  h1 {
    font-size: 40px;
    @include md() {
      font-size: 60px;
    }
    color: $yellow;
    margin: 36px 0 0;
    word-break: break-word;
  }
  h2 {
    @include md() {
      font-size: 24px;
    }
    color: $text-gray;
    margin: 0;
  }
}

.col-price,
.col-text {
  padding: 0;
  margin-bottom: 10px;
  h3 {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 28px;
  }
  p {
    margin-bottom: 24px;
  }
}

.col-price {
  background-color: $light-gray;
  border-radius: 12px;
  h3 {
    margin-bottom: 24px;
    font-size: 24px;
  }
  p {
    margin-bottom: 24px;
    font-size: 14px;
  }
  h3 + p {
    height: 132px;
  }
  .price {
    color: $text-color;
    font-size: 32px;
  }
  .price + span {
    padding-top: 6px;
  }
  a {
    color: $yellow;
    font-weight: bold;
    display: block;
    margin-bottom: 24px;
    &:hover {
      color: darken($yellow, 10%);
    }
  }
}

.button-order-wrap {
  margin: 72px 0;
}

button.order,
a.order {
  background: $gray;
  border: none;
  outline: none;
  color: white;
  padding: 16px 72px;
  border-radius: 8px;
  font-size: $font-size-button-large;
  transition: background-color 0.5s ease;
  @include sm() {
    font-size: $font-size-button-large-md;
  }
  @include lg() {
    font-size: $font-size-button-large-lg;
  }
  font-weight: 600;
  &:hover {
    background: darken($gray, 10%);
    color: white;
  }
}

.container-wrap-index-0,
.container-wrap-index-1 {
  background-color: $light-yellow;
  margin-bottom: 72px;
  .col-12 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.container-text-purple{
  position: absolute;
  left:-65px;
  top: 65%;
  z-index: 10;
  color:#CE0CC8;
  font-weight: 800;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 45%;
    left:-60px;
  }
}

.text {
  font-size:18px;
  font-family:helvetica;
  font-weight:bold;
  color:#CE0CC8;

}
.parpadea {

  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.container-arrow-purple{
  position: absolute;
  left:-30px;
  top: 70%;
  z-index: 11;
  @media (max-width: 768px) {
    top: 58%;
    left:-50px;
  }
}
$screen-xxs-min: 415px;
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
.container-btn{
  position: absolute;
  top: 90%;
  right: -350px;
  padding: 16px 10px;
  border-radius: 8px;
  font-size: $font-size-button-large;

  @media (max-width: 768px) {
    margin-top: 70px;
    position: absolute;
    padding: 16px 0;
    width: 300px;
    left:-60px;
  }
}

.container-wrap-index-0 {
  .col-12 {
    background-image: url('../../img/index/index-0.svg');
    height: 300px;
    @include md() {
      height: 864px;
    }

    .iphone-wrap {
      $width: 115px;
      $height: 227px;
      $widthMD: 255px;
      $heightMD: 505px;
      $margin: 8px;
      $marginMD: 15px;

      width: $width;
      height: $height;
      margin: 0 auto;
      margin-top: 60px;
      position: relative;
      @include md() {
        margin-top: 315px;
        width: $widthMD;
        height: $heightMD;
      }

      .iphone-background {
        position: absolute;
        background-color: rgba($light-gray, 0.8);
        margin: $margin;
        width: calc(100% - #{2 * $margin});
        height: calc(100% - #{2 * $margin});
        @include md() {
          margin: $marginMD;
          width: calc(100% - #{2 * $marginMD});
          height: calc(100% - #{2 * $marginMD});
        }
      }

      img.iphone {
        display: block;
        width: $width;
        height: $height;
        position: absolute;
        top: 0;
        pointer-events: none;
        @include md() {
          width: $widthMD;
          height: $heightMD;
        }
      }

      .iphone-extractions-wrap {
        width: 100%;
        height: 100%;

        img {
          width: calc(100% - #{2 * $margin});
          position: absolute;
          margin: $margin;
          top: 2px; // correct spacing in iphone image
          @include md() {
            width: calc(100% - #{2 * $marginMD});
            margin: $marginMD;
            top: 4px; // correct spacing in iphone image
          }
        }
      }

      $d: 23px;
      $dMD: 52px;

      .iphone-extractions-thumbs {
        $margin: 8px;
        $marginMD: 19px;
        width: calc(100% - #{2 * $margin});
        height: $d;
        position: absolute;
        margin: 0 $margin;
        bottom: 11px;
        overflow: hidden;
        @include md() {
          width: calc(100% - #{2 * $marginMD});
          height: $dMD;
          margin: 0 $marginMD;
          bottom: 26px;
        }

        > div {
          $num: 5;
          width: #{$num * $d};
          height: $d;
          @include md() {
            width: #{$num * $dMD};
            height: $dMD;
          }
        }
      }

      .iphone-extractions-thumb {
        background-image: url('../../img/index/iphone/extraction-place-holder@2x.png');
        background-size: contain;
        display: block;
        float: left;
        cursor: pointer;
        width: $d;
        height: $d;
        @include md() {
          width: $dMD;
          height: $dMD;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.container-wrap-index-1 {
  .col-12 {
    background-image: url('../../img/index/index-1.svg');
    height: 220px;
    @include md() {
      height: 600px;
    }
  }
}

.container-wrap-index-2 {
  margin-bottom: 50px;
}

// @TODO: Move to own file. Reuse in merchant.hbs

// @LINK: https://blog.theodo.fr/2018/01/responsive-iframes-css-trick/
.iframe-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    // weird hack for iOS Safari
    // @LINK: https://github.com/ampproject/amphtml/issues/11133
    height: 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 0;
  }
  .iframe-wrap-iframe-overlay {
    position: relative;
    background: none;
    padding-bottom: 100%;
    z-index: 1;
    .no-touch & {
      display: none;
    }
  }
}

.border-radius-4{
  border-radius: 4px;
}

#hosting-market {
  position: absolute;
  top: -190px;
  left: 50%;

  @media lg {
    top: -100px;
    left: 50%;
  } 
} 

#hosting-market-mobile {
  position: absolute;
  bottom: 80%;
  right: 20px;
  width: 150px;
}

.max-80 {
  width: 35%;
}