.menu-regions {
  margin: 0;
  margin-left: -4px;
  
  a.-is-active {
    color: #4A4A4A;
  }
  
  a {
    font-family: $font-helvetica-neue-regular;
    font-size: 16px;
    color: #C4C4C4;
  }
  
  li:not(:first-child) a:before {
    content: '-\00a0\00a0';
    color: #C4C4C4;
  }
} 