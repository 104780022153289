.dot.is-big {
  width: 25px;
  height: 25px;
}

.dot {
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  color: #3bd671;
  background: #3bd671;
  position: relative;
  -ms-transform: none;
  transform: none;
}

.dot.is-big:after {
  animation: pulseBig 2s infinite;
}

.dot:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: currentColor;
  animation: pulse 2s infinite;
  opacity: 1;
  border-radius: 50%;
  top: 0;
  left: 0;
}

@keyframes pulseBig {
  0% {
    opacity: 0.5
  }

  70% {
    opacity: 0;
    transform: scale(1.6)
  }

  100% {
    opacity: 0
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5
  }

  70% {
    opacity: 0;
    transform: scale(2.5)
  }

  100% {
    opacity: 0
  }
}