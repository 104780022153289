a.-is-underlined,
button.-is-underlined {
  $text-color: $text-color;
  $color: $yellow;
  $height: 3px;

  display: inline-block;
  position: relative;
  color: $text-color;
  text-decoration: none;
  padding-bottom: $height;

  &:hover {
    color: $text-color;
    text-decoration: none;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $height;
    bottom: 0px;
    left: 0;
    background-color: $color;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:before,
  &:active:before,
  &.-is-active:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

button.-is-underlined:focus {
  outline: none;
}
