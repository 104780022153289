$screen-xxs-min: 415px;
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

@mixin xxs {
  @media (max-width: $screen-xxs-min - 1) {
      @content;
    }
}

@mixin xs {
  @media (max-width: $screen-sm-min - 1) {
      @content;
    }
}

@mixin xsm {
  @media (min-width: $screen-xs-min) {
    @content;
  }
}

@mixin sm {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin md {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin lg {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}


@mixin maxmd {
  @media (max-width: $screen-md-min) {
    @content;
  }
}

// @LINK: https://css-tricks.com/snippets/css/retina-display-media-query/
@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}
