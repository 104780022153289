button#similar-shops-right,
button#similar-shops-left {
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 22px;
  margin: 16px;
}
button#similar-shops-right {
  background-image: url("../img/arrow-right.svg");
}
button#similar-shops-left {
  background-image: url("../img/arrow-left.svg");
}

.header-similar-shops {
  font-size: 24px;
  color: $text-gray;
}

.similar-shops {
  overflow: hidden;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .merchant-wrap {
    max-width: 360px;
    margin: 24px 0px;
    float: left;

    a.relative {
      margin-right: 5px;
      margin-left: 5px;
    }

    .merchant-content {
      h1 {
        color: white;
        padding: 0 0px;
        bottom: 12px;
        @include xsm {
          font-size: 22px;
          padding: 0 24px;
          bottom: 24px;
        }
      }
    }
  }
}
